/** @jsx jsx */
import { Box, Link, jsx } from 'theme-ui'

import { Metadata, siteTitle } from '@components/Metadata'
import { Button } from '@components/ui/Button'
import { useEffect } from 'react'

const navigate = () => {
  window.location.href =
    'https://cinekid.nl/nl/jaarrond/voor-ouders/korte-films/'
}

export default function PlaceholderPage() {
  useEffect(() => {
    setTimeout(navigate, 30_000)
  }, [])

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: ['flex-start', 'center', 'center'],
        height: ['100%', '100vh', '100vh'],
      }}
    >
      <Metadata>
        <title>{siteTitle}</title>
        <style>
          {`
            body {
              background-image: url('/images/cinekid_bg.jpg');
              background-size: cover;
              background-position: center;
            }
          `}
        </style>
      </Metadata>
      <Box
        sx={{
          width: '90%',
          maxWidth: '700px',
          textAlign: 'center',
        }}
      >
        <div
          sx={{
            position: 'fixed',
            top: '-200px',
            left: '-200px',
            right: '-200px',
            bottom: '-200px',
            zIndex: -1,
            backgroundColor: [
              'rgba(0, 0, 0, 0.3)',
              'transparent',
              'transparent',
            ],
          }}
        />
        <h1
          sx={{
            fontSize: [5, 6, 6],
            fontWeight: 'bold',
            color: 'white',
            mb: 3,
          }}
        >
          Cinekid Play is gestopt!
        </h1>
        <p
          sx={{
            fontSize: [2, 3, 3],
            color: 'white',
            mb: 3,
          }}
        >
          2,5 jaar lang hebben we hier de leukste en spannendste korte films,
          games en workshops met jullie mogen delen en dat was te gek! Nu de
          noodzaak voor een online platform (wat ontstaan is door de pandemie)
          er niet meer is, kiezen we ervoor om te focussen op ons film- en media
          festival voor kinderen (de grootste ter wereld!), Cinekid Festival.
          <br />
          <br />
          Ben je nieuwsgierig? Meld je dan{' '}
          <Link
            sx={{
              color: 'white',
            }}
            href="https://cinekid.nl/#newsletter"
          >
            hier
          </Link>{' '}
          aan voor de Cinekid nieuwsbrief.
          <br />
          <br />
          Ontdek nu meer dan 25 bijzondere korte films op cinekid.nl!
        </p>
      </Box>
      <Button variant="secondary" onClick={navigate}>
        Kijk hier!
      </Button>
      <div sx={{ color: 'transparent' }}>.</div>
      <div sx={{ color: 'transparent' }}>.</div>
    </div>
  )
}
